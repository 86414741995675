
import { defineComponent } from "vue";
import RedacaoRelatorioColumn from "@/util/table-columns/RedacaoRelatorioColumn";
import useList from "@/modules/useList";
import store from "@/store";
import {useRoute} from "vue-router";

export default defineComponent({
  components: {  },
  setup() {
    const route = useRoute();
    const {
      rows,
      loading,
      filter,
      columns,
      list,
      formatData,
      modalForm,
      openModalForm,
    } = useList(
      store.getters.divisaoId
        ? `redacoes/relatorio/${store.getters.divisaoId}/${route.params.id}`
        : `redacoes/relatorio/${route.params.id}`,
      RedacaoRelatorioColumn
    );

    return {
      rows,
      loading,
      filter,
      columns,
      list,
      modalForm,
      openModalForm,
      formatData,
    };
  },
});
