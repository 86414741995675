import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_table, {
        title: "Relatório de Redação",
        "row-key": "id",
        rows: _ctx.rows,
        columns: _ctx.columns,
        filter: _ctx.filter,
        loading: _ctx.loading,
        pagination: { rowsPerPage: 10 }
      }, null, 8, ["rows", "columns", "filter", "loading"])
    ])
  ]))
}