const RedacaoRelatorioColumn = [
  {
    name: "professor",
    align: "left",
    label: "Nome do Professor",
    field: "professor",
    sortable: true,
  },
  {
    name: "total",
    align: "left",
    label: "Total Corrigido",
    field: "total",
    sortable: true,
  },
];

export default RedacaoRelatorioColumn;
